<template>
	<div>
		<div style="display: flex;margin-top: 5%">
			<el-input v-model="question" placeholder="请输入问题"/>
			<el-button @click="getAnswer">提问</el-button>
		</div>
		<el-divider/>
		<div style="margin-top: 5%">
			<div v-for="(item, index) in answers" :key="index">
				<div style="margin: 2%">
					{{ item }}
				</div>
				<el-divider/>
			</div>
		</div>
	</div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";

export default {
	name: "",
	inject: ['reload'],
	computed: {
		...mapGetters(['GET_BDST']),
		...mapGetters(['GET_USER'])
	},
	data() {
		return {
			question: '',
			answers: []
		}
	},
	created() {
	},
	methods: {
		getAnswer(){
			this.axios.get('/XfXh/sendQuestion', (response) => {
				this.answers.push(response.obj)
			},{
				question: this.question
			})
		}
	}
}
</script>

<style>

</style>